// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "x_sx d_gv d_cs";
export var heroHeaderCenter = "x_gw d_gw d_cs d_dw";
export var heroHeaderRight = "x_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "x_sy d_gr d_cw";
export var heroParagraphCenter = "x_gs d_gs d_cw d_dw";
export var heroParagraphRight = "x_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "x_sz d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "x_sB d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "x_sC d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "x_sD d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "x_sF d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "x_sG G_sG";
export var heroExceptionNormal = "x_sH G_sH";
export var heroExceptionLarge = "x_sJ G_sJ";
export var Title1Small = "x_sK G_sK G_t4 G_t5";
export var Title1Normal = "x_sL G_sL G_t4 G_t6";
export var Title1Large = "x_sM G_sM G_t4 G_t7";
export var BodySmall = "x_rV G_rV G_t4 G_vn";
export var BodyNormal = "x_rW G_rW G_t4 G_vp";
export var BodyLarge = "x_rX G_rX G_t4 G_vq";