// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "C_tS d_bD";
export var storyRowWrapper = "C_hx d_hx d_bK";
export var storyLeftWrapper = "C_tT d_bz d_bP";
export var storyWrapperFull = "C_tV d_cD";
export var storyWrapperFullLeft = "C_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "C_mv d_hy";
export var storyLeftWrapperCenter = "C_tW d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "C_tX d_hF";
export var storyHeader = "C_tY d_hD d_w d_cs";
export var storyHeaderCenter = "C_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "C_hB d_hB d_by d_dw";
export var storyBtnWrapper = "C_tZ d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "C_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "C_rz d_fg d_Z";
export var imageWrapperFull = "C_rB d_w d_H d_bf d_Z";
export var SubtitleSmall = "C_qd G_qd G_t4 G_vg";
export var SubtitleNormal = "C_qf G_qf G_t4 G_vh";
export var SubtitleLarge = "C_qg G_qg G_t4 G_vj";
export var textLeft = "C_dv";
export var textCenter = "C_dw";
export var textRight = "C_dx";