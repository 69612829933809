// extracted by mini-css-extract-plugin
export var lbContainerOuter = "J_wc";
export var lbContainerInner = "J_wd";
export var movingForwards = "J_wf";
export var movingForwardsOther = "J_wg";
export var movingBackwards = "J_wh";
export var movingBackwardsOther = "J_wj";
export var lbImage = "J_wk";
export var lbOtherImage = "J_wl";
export var prevButton = "J_wm";
export var nextButton = "J_wn";
export var closing = "J_wp";
export var appear = "J_wq";