// extracted by mini-css-extract-plugin
export var alignLeft = "t_qh d_bG";
export var alignCenter = "t_bP d_bD";
export var alignRight = "t_qj d_bH";
export var textAlignLeft = "t_rM";
export var textAlignCenter = "t_rN";
export var textAlignRight = "t_rP";
export var embedInnerWrapperDesign1 = "t_rQ d_bM";
export var embed = "t_rR d_b1";
export var titleMargin = "t_rD d_cw d_dw";
export var subtitleMargin = "t_rS d_cs d_dw";
export var paragraphMargin = "t_rT d_cw d_dw";
export var SubtitleSmall = "t_qd G_qd G_t4 G_vg";
export var SubtitleNormal = "t_qf G_qf G_t4 G_vh";
export var SubtitleLarge = "t_qg G_qg G_t4 G_vj";
export var BodySmall = "t_rV G_rV G_t4 G_vn";
export var BodyNormal = "t_rW G_rW G_t4 G_vp";
export var BodyLarge = "t_rX G_rX G_t4 G_vq";