// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "s_rc d_bD";
export var datasheetRowWrapper = "s_lt d_lt d_bK";
export var datasheetLeftWrapper = "s_rd d_bz d_bP";
export var datasheetWrapperFull = "s_rf d_cD";
export var datasheetWrapperFullLeft = "s_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "s_mv d_lv";
export var contentWrapperCenter = "s_rg d_lz";
export var datasheetLeftWrapperCenter = "s_rh d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "s_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "s_rj d_lw";
export var datasheetFooter = "s_ly d_ly";
export var alignLeft = "s_qh d_dv";
export var alignCenter = "s_bP d_dw";
export var alignRight = "s_qj d_dx";
export var datasheetInnerWrapperNewLeft = "s_rk d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "s_rl d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "s_rm d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "s_rn d_bK";
export var datasheetSubtitle = "s_rp d_cx";
export var tableRow = "s_rq";
export var cell = "s_rr";
export var tableRowWrapper = "s_rs d_w d_dv";
export var tableHeadingCell = "s_rt";
export var tableHeading = "s_rv";
export var tableRowStriped = "s_rw";
export var tableRowHeading = "s_rx";
export var dataTable = "s_ry d_w";
export var imageWrapper = "s_rz d_fg d_Z";
export var imageWrapperFull = "s_rB d_H d_w d_bf d_Z";
export var imageWrapperIcon = "s_rC";
export var titleMargin = "s_rD d_cs";
export var datasheetInnerInnerWrapper = "s_rF d_w";
export var hasLabels = "s_rG";
export var label = "s_rH";
export var SmallSmall = "s_rJ G_rJ G_t4 G_vr";
export var SmallNormal = "s_rK G_rK G_t4 G_vs";
export var SmallLarge = "s_rL G_rL G_t4 G_vp";